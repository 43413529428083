@import "@/src/styles/common.scss";
@keyframes zIndexZero {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.app_bar_container.app_content_wrapper {
    flex-direction: initial;
    margin: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    justify-content: space-between;
    padding: 0;
    width: 100%;
    height: 1rem;
    box-sizing: border-box;
    border-bottom: 0.02rem solid #ccc;
    background: #fff;
    box-shadow: 0 0.04rem 0.08rem rgba(0, 0, 0, 0.16);
    transition:
        left 0.3s ease-out,
        right 0.3s ease-out;
    display: flex;
    align-items: center;
    // appbarlabel
}
